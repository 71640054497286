import { render, staticRenderFns } from "./mini-product.vue?vue&type=template&id=dd442058&scoped=true"
import script from "./mini-product.vue?vue&type=script&lang=js"
export * from "./mini-product.vue?vue&type=script&lang=js"
import style0 from "./mini-product.vue?vue&type=style&index=0&id=dd442058&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd442058",
  null
  
)

export default component.exports