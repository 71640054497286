<template>
  <Layout>
    <products-view :shop-id="$store.getters['parameters/getAderloShopId']" />
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main.vue";
import ProductsView from "@/components/product/products-view.vue";

export default {
  components: {
    ProductsView,
    Layout
  }
}
</script>