<script>
export default {
  name: "advanced-product-title",

  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div class="col-xl-6 py-1 advanced-product-info-box">
    <div class="advanced-product-info">
      {{ title }} <span>{{ subTitle }}</span>
    </div>
  </div>
</template>

<style scoped>
.advanced-product-info {
  background-color: var(--product-advanced-info-bg);

  font-size: 12px;
  font-weight: bold;

  padding: 10px 15px;

  color: var(--product-advanced-info);

  border-radius: 5px;
}

.advanced-product-info span {
  font-size: 12px;
  font-weight: normal;
  color: var(--product-advanced-info-title);

  padding-left: 5px;
}

@media (min-width: 1280px) {
  .advanced-product-info-box:nth-child(odd) {
    padding-right: 4px;
  }

  .advanced-product-info-box:nth-child(even) {
    padding-left: 4px;
  }
}
</style>