<template>
  <b-modal
      v-model="visibleModal"
      :title="$t('products.add.title')"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal"
      @shown="refreshSlidebar">
    <form>
      <div class="form-group" v-if="!$store.getters['shop/shopIsVirtual'](form.shopId)">
        <div class="custom-control custom-checkbox mb-3">
          <input id="send-to-store" v-model="form.sendToStore" type="checkbox" class="custom-control-input"/>
          <label for="send-to-store" class="custom-control-label">Wyślij na sklep internetowy: {{ shop ? shop.name : '-' }}</label>
        </div>
      </div>

      <div class="form-group" v-if="allegroAccountCount > 0">
        <div class="custom-control custom-checkbox mb-3">
          <input id="send-to-allegro" v-model="form.sendToMarketplace" type="checkbox" class="custom-control-input"/>
          <label for="send-to-allegro" class="custom-control-label">Wyślij produkty na Marketplace</label>
        </div>
      </div>

      <div class="form-group" v-if="form.sendToMarketplace">
        <label>Wybór marketplace</label>
        <div class="add-products-modal-centered-content">
          <vue-multiselect
              v-model="form.marketplace"
              :options="['ALLEGRO']"
              :custom-label="getMarketplaceLabel"
              placeholder="Wybierz Marketplace"
              :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <div class="add-products-modal-centered-label">
                <img :src="getMarketplaceLogo(option)" alt="Icon" class="icon" :style="$store.getters['theme/isThemeDark'] ? '' : 'filter: invert(100%);'" />
                {{ getMarketplaceLabel(option) }}
              </div>
            </template>

            <template slot="option" slot-scope="{ option }">
              <div class="add-products-modal-centered-label">
                <img :src="getMarketplaceLogo(option)" alt="Icon" class="icon" :style="$store.getters['theme/isThemeDark'] ? '' : 'filter: invert(100%);'" />
                {{ getMarketplaceLabel(option) }}
              </div>
            </template>
          </vue-multiselect>
        </div>
      </div>

      <template v-if="form.sendToMarketplace && form.marketplace === 'ALLEGRO'">
        <div class="form-group">
          <label for="allegro-account-name">{{ $t('products.modal.allegro-account.title') }}</label>

          <ecat-multiselect
              v-if="form.shopId"
              :set-value="$store.getters['allegro/getCurrentAllegroAccountId']"
              @change="value => changeAllegroAccount(value)"
              :placeholder="$t('products.modal.allegro-account.select')"
              :fetch-one-url="`/allegro/${form.shopId}`"
              :load-url="`/allegro/list/pagination/${form.shopId}`"
              :query-url="`/allegro/by-name/${form.shopId}`"
              save-id="id"
              view-id="name"
              param="name"
              :class="{ 'is-invalid': $v.form.allegroAccountId.$error }"
          />

          <div v-if="!$v.form.allegroAccountId.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group" id="allow-listing-in-paid-categories-info">
          <div class="d-inline-flex">
            <div class="custom-control custom-checkbox">
              <input id="allow-listing-in-paid-categories" v-model="form.allowListingInPaidCategories" type="checkbox" class="custom-control-input"/>
              <label for="allow-listing-in-paid-categories" class="custom-control-label">Czy chcesz wystawić produkty z płatnych kategorii?</label>
            </div>

            <i class="ri ri-information-line feather feather-info mx-2 font-size-16" />
          </div>
        </div>

        <b-tooltip target="allow-listing-in-paid-categories-info" placement="bottom">Allegro nalicza dodatkowe opłaty za wystawianie produktów w niektórych kategoriach. Niektóre opłaty potrafią wynosić aż 5 złotych od aukcji. Więcej informacji dostępne na: <a href="https://help.allegro.com/fees/pl#oplata-za-wystawienie">https://help.allegro.com/fees/pl#oplata-za-wystawienie</a></b-tooltip>

        <div class="from-group">
          <label>Wyślij również na:</label>

          <div class="custom-control custom-checkbox mb-3">
            <input id="CZECH_REPUBLIC" value="CZECH_REPUBLIC" @change="selectOrUnselectAllegroMarketplace('CZECH_REPUBLIC')" :checked="$store.getters['allegro/getSelectedMarketplaces'].includes('CZECH_REPUBLIC')" type="checkbox" class="custom-control-input"/>
            <label for="CZECH_REPUBLIC" class="custom-control-label">Allegro Czechy</label>
          </div>

          <div class="custom-control custom-checkbox mb-3">
            <input id="SLOVAKIA" value="SLOVAKIA" @change="selectOrUnselectAllegroMarketplace('SLOVAKIA')" :checked="$store.getters['allegro/getSelectedMarketplaces'].includes('SLOVAKIA')" type="checkbox" class="custom-control-input"/>
            <label for="SLOVAKIA" class="custom-control-label">Allegro Słowacja</label>
          </div>
        </div>

        <div class="form-group">
          <p class="text-danger">{{ $t('products.modal.warning.1') }}</p>
          <p class="text-danger">{{ $t('products.modal.warning.2') }}</p>
        </div>

<!--        <div class="form-group" v-if="form.sendToMarketplace && form.marketplace === 'ALLEGRO'">-->
<!--          <label>{{ $t('products.modal.delivery.title') }}</label>-->

<!--          <div>-->
<!--            <input type="radio" id="SMART" value="SMART" v-model="form.priceType" name="SMART"-->
<!--                   :class="{ 'is-invalid': $v.form.priceType.$error }">-->
<!--            <label for="SMART" style="margin-left: 5px;">{{ $t('products.modal.delivery.smart') }}</label>-->
<!--          </div>-->

<!--          <div>-->
<!--            <input type="radio" id="WAREHOUSE" value="WAREHOUSE" v-model="form.priceType" name="SMART"-->
<!--                   :class="{ 'is-invalid': $v.form.priceType.$error }">-->
<!--            <label for="WAREHOUSE" style="margin-left: 5px;">{{ $t('products.modal.delivery.warehouse') }}</label>-->
<!--          </div>-->

<!--          <div v-if="!$v.form.priceType.required" class="text-danger">{{ $t('message.required') }}</div>-->

<!--          <p class="text-danger">Cena produktów może zostać podwyższona celem zmaksymalizowania zysku ze sprzedaży-->
<!--            produktu ze względu na wybraną opcję dostawy</p>-->
<!--        </div>-->

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="only-products-in-range" v-model="form.onlyProductsInRange" type="checkbox"
                   class="custom-control-input"/>
            <label for="only-products-in-range"
                   class="custom-control-label">{{ $t('products.modal.only-products-in-range') }}</label>
          </div>
        </div>

        <div class="row" v-if="form.onlyProductsInRange">
          <div class="form-group col-6">
            <label for="minPrice">{{ $t('products.modal.price.min') }}</label>
            <div class="input-group">
              <span class="input-group-btn input-group-prepend">
                <button @click="decrementMinPrice" class="btn btn-danger" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line></svg></button>
              </span>
              <input type="number" inputmode=”numeric” class="form-control" v-model.number="form.minPrice"
                     name="minPrice" min="1">
              <span class="input-group-btn input-group-append">
                <button @click="form.minPrice += 0.50;" class="btn btn-success" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12"
                                                                                                   x2="19"
                                                                                                   y2="12"></line></svg>
                </button>
              </span>
            </div>
          </div>

          <div class="form-group col-6">
            <label for="maxPrice">{{ $t('products.modal.price.max') }}</label>
            <div class="input-group">
              <span class="input-group-btn input-group-prepend">
                <button @click="decrementMaxPrice" class="btn btn-danger" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line></svg></button>
              </span>
              <input type="number" inputmode=”numeric” class="form-control" v-model.number="form.maxPrice"
                     name="maxPrice" min="1">
              <span class="input-group-btn input-group-append">
                <button @click="form.maxPrice += 0.50;" class="btn btn-success" type="button"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12"
                                                                                                   x2="19"
                                                                                                   y2="12"></line></svg>
                </button>
              </span>
            </div>
          </div>
        </div>
      </template>

      <div class="form-group" v-if="!$store.getters['shop/shopIsVirtual'](form.shopId) && form.sendToStore">
        <label for="name">{{ $t('products.add.select-shop-category') }}</label>
        <ecat-multiselect
            @change="value => form.shopCategoryId = value"
            view-id="displaySlug"
            save-id="id"
            placeholder="Wybierz kategorię"
            :load-url="`/shop/${form.shopId}/category/list/pagination`"
            :query-url="`/shop/${form.shopId}/category/by/name`"
            param="name"
            :class="{ 'is-invalid': $v.form.shopCategoryId.$error }"
        />

        <div v-if="!$v.form.shopCategoryId.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label for="price-increase-value">{{ $t('products.add.increase-profit-by-price') }}</label>
        <input id="price-increase-value" v-model="form.priceIncreaseValue" type="text" name="price-increase-value"
               class="form-control"/>
      </div>

      <div class="form-group">
        <label for="price-increase-percent">{{ $t('products.add.increase-profit-by-percent') }}</label>
        <input id="price-increase-percent" v-model="form.priceIncreasePercent" type="text"
               name="price-increase-percent" class="form-control"/>
      </div>

      <div class="form-group">
        <label for="profit-lowered-by-percent">{{ $t('products.add.reduce-profit') }}</label>
        <vue-slide-bar
            id="profit-lowered-by-percent"
            ref="slidebar"
            v-model="form.profitLoweredByPercent"
            :min="1"
            :max="50"/>
      </div>

      <p v-html="$t('products.add.selected', { 'amount': form.productIds.length })"></p>

      <div class="text-right">
        <ecat-button :callback="addProducts" variant="success" :message="$t('products.add.button')"/>
        <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </form>
  </b-modal>

</template>

<script>
import VueSlideBar from "vue-slide-bar";
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {toastHelper} from "@/helpers/toast-helper";

export default {
  components: {
    VueSlideBar
  },

  data() {
    return {
      visibleModal: false,
      allegroAccountCount: 0, // https://gitlab.com/ec-at/bugtracker/-/issues/739

      shop: null,
      form: {
        shopId: "",
        productIds: [],
        shopCategoryId: null,
        allegroAccountId: null,
        profitLoweredByPercent: 1,
        priceIncreaseValue: 0,
        priceIncreasePercent: 0,
        onlyProductsInRange: false,
        allowListingInPaidCategories: false,
        minPrice: 50.0,
        maxPrice: 50.0,

        // priceType: "",

        sendToStore: false,
        sendToMarketplace: false,
        marketplace: ""
      }
    }
  },

  validations() {
    let categoryValidator = {}
    if (this.form.sendToStore) {
      categoryValidator = {
        shopCategoryId: { required }
      }
    }

    if (this.form.sendToMarketplace && this.form.marketplace === 'ALLEGRO') {
      return {
        form: {
          allegroAccountId: { required },
          // priceType: {required},
          ...categoryValidator
        }
      }
    }

    return {
      form: {
        ...categoryValidator
      }
    }
  },

  methods: {

    async selectOrUnselectAllegroMarketplace(allegroMarketplace) {
      await this.$store.dispatch("allegro/addOrRemoveMarketplace", allegroMarketplace)
    },

    async changeAllegroAccount(allegroAccountId) {
      this.form.allegroAccountId = allegroAccountId
      await this.$store.dispatch("allegro/setCurrentAllegroAccountId", allegroAccountId)
    },

    async loadShop(id) {
      try {
        const {data} = await axios.get(`/shop/${id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shop = data
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error
      }
    },

    async openModal(shopId, productIds) {
      this.form.shopId = shopId
      this.form.productIds = productIds

      try {
        await this.loadShop(shopId)
      } catch (error) {
        return
      }

      await this.loadAllegroAccountCount()
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false;

      this.shop = null
      this.form.shopId = ""
      this.form.shopCategoryId = null;
      this.form.allegroAccountId = null
      this.form.profitLoweredByPercent = 1;
      this.form.priceIncreaseValue = 1;
      this.form.priceIncreaseValue = 0;
      this.form.priceIncreasePercent = 0;
      this.form.onlyProductsInRange = false
      // this.form.priceType = ""
      this.$v.form.$reset()
    },

    getMarketplaceLabel() {
      return ""
    },

    getMarketplaceLogo(marketplace) {
      if (!marketplace) {
        return ""
      }

      switch (marketplace) {
        case "ALLEGRO":
          return require("@/assets/images/brands/allegro.svg")
      }

      return ""
    },

    async addProducts() {
      if (!this.form.shopId) {
        toastHelper.error("Wybierz sklep!")
        return
      }

      await this.addProductsToShop()
    },

    async addProductsToShop() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      const formPrevious = Object.assign({}, this.form)
      const json = JSON.stringify(formPrevious);

      if (this.form.sendToMarketplace && this.form.marketplace === 'ALLEGRO') {
        this.$emit("clear-product-ids")
        await this.sendProductsToAllegro(formPrevious)
        await this.$emit("load-products-count")
        this.hideModal()
      } else {
        try {
          const {data} = await axios.post(`/shop/${this.form.shopId}/product`, json, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
          })

          toastHelper.success(this.$t("shop-products.add.success", {"amount": this.form.productIds.length}))

          const shop = this.shop

          this.$emit("clear-product-ids")
          this.hideModal()

          await this.sendProductsToMagento(shop, formPrevious, data.map(shopProduct => shopProduct.id))
          await this.$emit("load-products-count")
        } catch (error) {
          this.$emit("clear-product-ids")
          this.hideModal()
          const message = errorCatcher.catchErrors(error)
          const ecatError = errorCatcher.getError(error)
          if (ecatError) {
            if (ecatError.type === "ShopProductErrors" && ecatError.code === 8) {
              Swal.fire("Błąd!", message, "error").then(() => this.$emit("open-shop-product-limit-modal", this.form.shopId))
            }
          }
        }
      }
    },

    async sendProductsToAllegro(form) {
      const json = JSON.stringify({
        allegroAccountId: form.allegroAccountId,
        targetType: form.marketplace,
        priceIncreasePercent: form.priceIncreasePercent,
        priceIncreaseValue: form.priceIncreaseValue,
        profitLoweredByPercent: form.profitLoweredByPercent,
        productIds: form.productIds,
        additionalMarketplaces: this.$store.getters['allegro/getSelectedMarketplaces'],
        priceRanges: form.onlyProductsInRange,
        priceMin: form.onlyProductsInRange ? form.minPrice : null,
        priceMax: form.onlyProductsInRange ? form.maxPrice : null,
        allowListingInPaidCategories: form.allowListingInPaidCategories,
      });

      const count = this.form.productIds.length

      this.$emit("clear-product-ids")

      try {
        await axios.post(`/shop/${form.shopId}/product/queue`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        })

        toastHelper.success(`Dodano: ${count} produktów do kolejki wysyłania na Allegro`)
      } catch (error) {
        const ecatError = errorCatcher.getError(error)
        if (ecatError) {
          const message = errorCatcher.catchErrors(error)
          if (ecatError.type === "ShopProductErrors" && ecatError.code === 8) {
            Swal.fire("Błąd!", message, "error").then(() => this.$emit("open-shop-product-limit-modal", this.form.shopId))
          } else if (ecatError.type === "AllegroAuctionCronErrors" && ecatError.code === 18) {
            toastHelper.success(`Dodano: 0 produktów na Allegro`)
            toastHelper.success("Wszystkie zaznaczone produkty są już wysłane do Allegro (z takim samym kodem EAN)")
          } else {
            errorCatcher.catchErrors(error)
          }
        }
      }

      this.hideModal()
    },

    async sendProductsToMagento(shop, data, shopProductIds) {
      if (!this.$store.getters['engine/isMagento'](shop.engineId)) {
        return
      }

      const json = JSON.stringify({
        shopId: shop.id,
        shopProductIds: shopProductIds,
        priceRanges: data.onlyProductsInRange,
        priceMin: data.minPrice,
        priceMax: data.maxPrice
      });

      try {
        const { data } = await axios.post(`/shop/auction/cron`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        })

        toastHelper.success(`Dodano: ${data.countAdded} produktów na Magento`)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      this.hideModal()
    },

    refreshSlidebar() {
      this.$refs.slidebar.refresh()
    },

    decrementMinPrice() {
      if (this.form.minPrice <= 1.0) {
        this.form.minPrice = 1.0;
        return;
      }

      this.form.minPrice -= 0.50;
    },

    decrementMaxPrice() {
      if (this.form.maxPrice <= 1.0) {
        this.form.maxPrice = 1.0;
        return;
      }

      this.form.maxPrice -= 0.50;
    },

    async loadAllegroAccountCount() {
      try {
        const {data} = await axios.get(`/allegro/list/pagination/${this.form.shopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: 0,
            size: 1
          }
        });

        this.allegroAccountCount = data.length
      } catch (error) {
        // ignored
      }
    }
  }

}
</script>

<style>
.add-products-modal-centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-products-modal-centered-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>