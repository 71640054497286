<script>
import OperationPrice from "@/components/product/operation-price.vue";
import OperationPriceBox from "@/components/product/operation-price-box.vue";
import {productsHelper} from "@/helpers/products-helper";

export default {
  name: "product-price-detail-modal",
  components: {OperationPriceBox, OperationPrice},

  computed: {
    productsHelper() {
      return productsHelper
    }
  },

  data() {
    return {
      visibleModal: false,
      product: null
    }
  },

  methods: {
    async openModal(product) {
      this.visibleModal = true
      this.product = product
    },

    hideModal() {
      this.visibleModal = false
      this.product = null
    },

    profitMargin() {
      if (this.product.warehouseProfitMargin > 0) {
        return this.product.warehouseProfitMargin
      }

      return this.product.profitMargin
    },

    expectedNettoPrice() {
      return this.product.priceWholesaleHigh + (this.profitMargin() / 100) * this.product.priceWholesaleHigh
    },

    logisticsPrice() {
      return this.product.priceHigh * 0.02
    }
  }

}
</script>

<template>
  <div>
    <b-modal
        v-if="product"
        size="xl"
        :scrollable="true"
        v-model="visibleModal"
        title="Szczegóły ceny produktu"
        title-class="font-18"
        hide-footer
        centered
        @hide="hideModal"
        @esc="hideModal">

      <div class="row pb-3">
        <div class="col-xl-1 d-flex align-items-center">
          <img :src="product.images[0]" class="img-info" alt="Product Icon">
        </div>
        <div class="col-xl-11 card-details-title">
          {{ product.name }}
        </div>
      </div>
      <div class="row nopadding pb-3">
        <div class="col-12 card-details-subtitle pb-2">
          Wyliczanie ceny sklepu
        </div>

        <div class="col-12">
          <div class="row py-2">
            <operation-price-box>
              <template v-slot>
                Cena hurtowni netto:<span>{{ productsHelper.parsePrice(product.priceWholesaleHigh) }}</span>
              </template>
            </operation-price-box>
            <operation-price operation="add"/>
            <operation-price-box>
              <template v-slot>
                Narzut<span>{{ profitMargin() }}%</span>
              </template>
            </operation-price-box>
            <operation-price operation="equals"/>
            <operation-price-box is-result>
              <template v-slot>
                Cena sprzedaży netto:<span>{{ productsHelper.parsePrice(expectedNettoPrice()) }}</span>
              </template>
            </operation-price-box>
          </div>
          <div class="row py-2">
            <operation-price-box>
              <template v-slot>
                Cena sprzedaży netto:<span>{{ productsHelper.parsePrice(expectedNettoPrice()) }}</span>
              </template>
            </operation-price-box>
            <operation-price operation="add"/>
            <operation-price-box>
              <template v-slot>
                Podatek VAT<span>{{ product.tax.rate }}%</span>
              </template>
            </operation-price-box>
            <operation-price operation="equals"/>
            <operation-price-box is-result>
              <template v-slot>
                Cena sklepu brutto:<span>{{ productsHelper.priceHighFormatter(null, null, product) }}</span>
              </template>
            </operation-price-box>
          </div>
        </div>

      </div>
      <div class="row nopadding">
        <div class="col-12 card-details-subtitle pb-2">
          Wyliczanie przewidywanego zysku ze sprzedaży
        </div>

        <div class="col-12">
          <div class="row py-2">
            <operation-price-box>
              <template v-slot>
                Cena sklepu brutto:<span>{{ productsHelper.priceHighFormatter(null, null, product) }}</span>
              </template>
            </operation-price-box>
            <operation-price operation="multiply"/>
            <operation-price-box>
            <template v-slot>
              2% Koszty logistyki i księgowości, program lojalnościowy Twojego sklepu
            </template>
          </operation-price-box>
            <operation-price operation="equals"/>
            <operation-price-box is-result>
              <template v-slot>
                <span>{{ productsHelper.parsePrice(logisticsPrice()) }}</span>
              </template>
            </operation-price-box>
          </div>
          <div class="row py-2">
            <operation-price-box>
              <template v-slot>
                Cena sprzedaży netto:<span>{{ productsHelper.parsePrice(expectedNettoPrice()) }}</span>
              </template>
            </operation-price-box>
            <operation-price operation="substract"/>
            <operation-price-box>
              <template v-slot>
                Cena hurtowa netto <span>{{ productsHelper.parsePrice(product.priceWholesaleHigh) }} + {{ productsHelper.parsePrice(logisticsPrice()) }}</span> Koszty logistyki i księgowości, program lojalnościowy
                Twojego sklepu = <span>{{ productsHelper.parsePrice(product.priceWholesaleHigh + logisticsPrice()) }}</span>
              </template>
            </operation-price-box>
            <operation-price operation="equals"/>
            <operation-price-box is-result>
              <template v-slot>
                <span>{{ productsHelper.parsePrice(expectedNettoPrice()) }} - {{ productsHelper.parsePrice(product.priceWholesaleHigh + logisticsPrice()) }} = {{ productsHelper.parsePrice(expectedNettoPrice() - (product.priceWholesaleHigh + logisticsPrice())) }}</span>
              </template>
            </operation-price-box>
          </div>
          <div class="row py-2">
            <operation-price-box>
              <template v-slot>
                Przewidywany zysk:<span>{{ productsHelper.parsePrice(expectedNettoPrice() - (product.priceWholesaleHigh + logisticsPrice())) }}</span>
              </template>
            </operation-price-box>
            <operation-price operation="multiply"/>
            <operation-price-box>
              <template v-slot>
                Przewidywany zysk ECAT:<span>{{ $store.getters['parameters/getEcatProfitDivision'] }}%</span>
              </template>
            </operation-price-box>
            <operation-price operation="equals"/>
            <operation-price-box is-result>
              <template v-slot>
                Przewidywany zysk ECAT:<span>{{ productsHelper.ecatProfitFormatter(null, null, product, product.tax.rate) }}</span>
              </template>
            </operation-price-box>
          </div>
          <div class="row py-2">
            <operation-price-box>
              <template v-slot>
                Przewidywany zysk:<span>{{ productsHelper.parsePrice(expectedNettoPrice() - (product.priceWholesaleHigh + logisticsPrice())) }}</span>
              </template>
            </operation-price-box>
            <operation-price operation="multiply"/>
            <operation-price-box>
              <template v-slot>
                Przewidywany zysk operatora:<span>{{ $store.getters['parameters/getOperatorProfitDivision'] }}%</span>
              </template>
            </operation-price-box>
            <operation-price operation="equals"/>
            <operation-price-box customClass="highlight">
              <template v-slot>
                Przewidywany zysk operatora:<span>{{ productsHelper.profitFormatter(null, null, product, product.tax.rate) }}</span>
              </template>
            </operation-price-box>
          </div>
        </div>

      </div>

    </b-modal>
  </div>
</template>

<style scoped>
.card-details-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--product-operation-title);
}

.card-details-subtitle {
  font-size: 12px;
  font-weight: bold;

  color: var(--product-operation-subtitle);

  border-bottom: 1px solid var(--product-operation-subtitle-border);
}
</style>