<script>
import AdvancedProductTitle from "@/components/product/advanced-product-title.vue";
import EcatCkeditor from "@/components/ecat-ckeditor.vue";
import MiniProduct from "@/components/product/mini-product.vue";
import {jsonUtil} from "@/helpers/json-util";
import {priceHelper} from "@/helpers/price-helper";
import {productsHelper} from "@/helpers/products-helper";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {routerHelper} from "@/helpers/router-helper";
import Swal from "sweetalert2";

export default {
  name: "advanced-product-detail-modal",

  props: {
    shopId: {
      type: String,
      required: true
    },

    categories: {
      type: Map,
      required: true
    },

    warehouses: {
      type: Map,
      required: true
    },

    taxes: {
      type: Map,
      required: true
    },

    deliveryUnits: {
      type: Map,
      required: true
    },

    shippingCosts: {
      type: Map,
      required: true
    }
  },

  computed: {
    productsHelper() {
      return productsHelper
    },

    priceHelper() {
      return priceHelper
    },

    jsonUtil() {
      return jsonUtil
    },

    missingAttributes() {
      return this.form.parameters.filter(element => !element.value).length
    }
  },

  components: {
    MiniProduct,
    EcatCkeditor,
    AdvancedProductTitle
  },

  data() {
    return {
      visibleModal: false,

      shopProduct: null,
      cacheProductInfo: null,
      productInfo: null,
      allegroAuctions: [],
      allegroAuctionErrors: [],

      filtering: {
        showAttributesWithErrors: false,
        name: ""
      },

      form: {
        catalogType: "",
        productVariation: {},
        categoryParameters: {},
        parameters: [],
        nameChanged: false,
        descriptionChanged: false,
        imagesChanged: false
      }
    }
  },

  methods: {
    async openModal(catalogType, shopProduct) {
      this.form.catalogType = catalogType
      await this.loadProductCatalog(shopProduct)
      this.visibleModal = true
      this.shopProduct = shopProduct
    },

    hideModal() {
      this.visibleModal = false

      this.shopProduct = null
      this.cacheProductInfo = null
      this.productInfo = null
      this.allegroAuctions = []
      this.allegroAuctionErrors = []

      this.filtering = {
        showAttributesWithErrors: false,
        name: ""
      }

      this.form = {
        catalogType: "",
        productVariation: {},
        categoryParameters: {},
        parameters: [],
        nameChanged: false,
        descriptionChanged: false,
        imagesChanged: false
      }
    },

    isShopCatalog() {
      return this.form.catalogType === "SHOP"
    },

    async loadProductCatalog(shopProduct) {
      try {
        const {data} = await axios.get(`/catalog/product-info/${shopProduct.productId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.cacheProductInfo = Object.assign({}, data)

        const productVariations = []
        for (const productVariation of this.cacheProductInfo.productVariation) {
          productVariations.push(Object.assign({}, productVariation))
        }

        this.cacheProductInfo.productVariation = productVariations
        this.productInfo = Object.assign({}, data)
        this.form.productVariation = this.productInfo.productVariation[0]
        await this.loadAllegroAuctions(shopProduct)
        await this.loadCategoryParameters(this.form.productVariation)
        await this.loadParametersChanges(this.form.productVariation)
        await this.loadProductVariationChanges(this.form.productVariation)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCategoryParameters(productVariation) {
      try {
        const json = {
          allegroAccountId: this.$store.getters['allegro/getCurrentAllegroAccountId'],
          productVariationId: productVariation.id
        }

        const {data} = await axios.post(`/allegro/product/information/category-parameters`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.form.categoryParameters = data
        this.form.parameters = []

        for (const categoryParameter of this.form.categoryParameters.allegroProductParameters) {
          this.form.parameters.push({
            name: categoryParameter.name,
            allegroParameterId: categoryParameter.id,
            type: categoryParameter.type,
            required: categoryParameter.required,
            requiredForProduct: categoryParameter.requiredForProduct,
            restrictions: categoryParameter.restrictions,
            dictionary: categoryParameter.dictionary,
            dictionaryIds: categoryParameter.dictionary ? categoryParameter.dictionary.map(element => element.id) : null,
            dictionaryNameMap: categoryParameter.dictionary ? new Map(categoryParameter.dictionary.map((obj) => [obj.id, obj.value])) : null,
            describesProduct: categoryParameter.options.describesProduct === "true",
            value: null
          })
        }

        for (const parameter of this.form.parameters) {
          const defaultParameter = this.form.categoryParameters.defaultSelectedParameters.find(element => element.id === parameter.allegroParameterId)
          if (defaultParameter && defaultParameter.values) {
            parameter.value = defaultParameter.values[0]
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    async loadParametersChanges(productVariation) {
      try {
        const {data} = await axios.get(this.isShopCatalog() ? `/catalog/shop/${this.shopId}/variation/${productVariation.id}/parameters` : `/catalog/user/variation/${productVariation.id}/parameters`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        for (const parameter of this.form.parameters) {
          const ownParameter = data.find(element => element.allegroParameterId === parameter.allegroParameterId)
          if (ownParameter) {
            if (parameter.type === "DICTIONARY" && parameter.restrictions.multipleChoices) {
              parameter.value = JSON.parse(ownParameter.value)
            } else {
              parameter.value = JSON.parse(ownParameter.value)[0]
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    category() {
      if (this.shopProduct.category) {
        return this.shopProduct.category
      }

      if (!this.categories || !this.shopProduct.shopCategoryId) {
        return null
      }

      return this.categories.get(this.shopProduct.shopCategoryId) || null;
    },

    warehouse() {
      if (this.shopProduct.warehouse) {
        return this.shopProduct.warehouse
      }

      if (!this.warehouses || !this.shopProduct.productWarehouseId) {
        return null
      }

      return this.warehouses.get(this.shopProduct.productWarehouseId) || null;
    },

    taxRate() {
      if (this.shopProduct.tax) {
        return this.shopProduct.tax.rate
      }

      if (!this.taxes || this.taxes.size === 0 || !this.shopProduct.productTaxId) {
        return 0
      }

      const tax = this.taxes.get(this.shopProduct.productTaxId);
      return tax ? tax.rate || 0 : 0;
    },

    deliveryUnit(deliveryUnit) {
      if (!this.deliveryUnits || !deliveryUnit) {
        return null
      }

      return this.deliveryUnits.get(deliveryUnit) || null
    },

    deliveryTime() {
      if (this.shopProduct.productDeliveryTime && this.shopProduct.productDeliveryUnit) {
        const deliveryUnit = this.deliveryUnit(this.shopProduct.productDeliveryUnit)
        if (deliveryUnit) {
          return this.shopProduct.productDeliveryTime + " " + deliveryUnit.name
        }
      }

      const warehouse = this.warehouse()
      if (warehouse) {
        const deliveryUnit = this.deliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    },

    shippingCostsForProduct() {
      if (this.shopProduct.shippingCosts) {
        return this.shopProduct.shippingCosts
      }

      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(this.shopProduct.productId) || null;
    },

    ean() {
      if (!this.shopProduct.productEan) {
        return []
      }

      if (Array.isArray(this.shopProduct.productEan)) {
        return this.shopProduct.productEan.filter(element => element)
      }

      return jsonUtil.asArray(this.shopProduct.productEan).filter(element => element)
    },

    eanString() {
      const ean = this.ean()
      if (ean && ean.length > 0) {
        if (ean[0]) {
          return ean[0].toString()
        }
      }

      return "-"
    },

    getDefaultProductVariationById(productVariation) {
      return this.cacheProductInfo.productVariation.find(element => element.id === productVariation.id)
    },

    resetName() {
      const defaultProductVariation = this.getDefaultProductVariationById(this.form.productVariation)
      if (defaultProductVariation) {
        this.form.productVariation.name = defaultProductVariation.name
        this.form.nameChanged = true

        const index = this.productInfo.productVariation.findIndex(element => element.id === this.form.productVariation.id);
        if (index !== -1) {
          this.productInfo.productVariation[index] = this.form.productVariation
          this.productInfo.productVariation = [...this.productInfo.productVariation]
        }
      }
    },

    resetDescription() {
      const defaultProductVariation = this.getDefaultProductVariationById(this.form.productVariation)
      if (defaultProductVariation) {
        this.form.descriptionChanged = true
        this.form.productVariation.description = defaultProductVariation.description
      }
    },

    resetImages() {
      const defaultProductVariation = this.getDefaultProductVariationById(this.form.productVariation)
      if (defaultProductVariation) {
        this.form.imagesChanged = true
        this.form.productVariation.images = defaultProductVariation.images
      }
    },

    async selectProductVariation(productVariation) {
      await this.loadProductVariationChanges(productVariation)
      await this.loadCategoryParameters(productVariation)
      this.form.productVariation = productVariation
    },

    async loadProductVariationChanges(productVariation) {
      try {
        const {data} = await axios.get(this.isShopCatalog() ? `/catalog/shop/${this.shopId}/variation/${productVariation.id}` : `/catalog/user/variation/${productVariation.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data.name) {
          productVariation.name = data.name
        }

        if (data.description) {
          productVariation.description = data.description
        }

        if (data.images) {
          productVariation.images = data.images
        }
      } catch (error) {
        console.log(error)
      }
    },

    setFavouriteImage(index) {
      this.form.imagesChanged = true
      const element = this.form.productVariation.images.splice(index, 1)[0];
      this.form.productVariation.images.unshift(element);
    },

    removeImage(index) {
      this.form.imagesChanged = true
      this.form.productVariation.images.splice(index, 1);
    },

    async saveProductVariationChanges() {
      try {
        await this.updateParameters()
      } catch (error) {
        return
      }

      try {
        const json = {
          productId: this.shopProduct.productId,
          name: this.form.nameChanged ? this.form.productVariation.name : null,
          description: this.form.descriptionChanged ? this.form.productVariation.description : null,
          images: this.form.imagesChanged ? JSON.stringify(this.form.productVariation.images) : null
        }

        await axios.post(this.isShopCatalog() ? `/catalog/shop/${this.shopId}/variation/${this.form.productVariation.id}` : `/catalog/user/variation/${this.form.productVariation.id}`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        Swal.fire("Sukces!", `Zmiany zostały zapisane!`, "success");
      } catch (error) {
        console.log(error)
      }
    },

    modifyWithAI(type) {
      this.$store.dispatch("aiproductsgenerate/setData", {
        productIds: [this.shopProduct.productId],
        generateName: type === "NAME",
        generateDescription: type === "DESCRIPTION"
      }).then(() => routerHelper.push("/dashboard/ai-products-generate"))
    },

    parameters() {
      let parameters = this.filtering.showAttributesWithErrors ? this.form.parameters.filter(element => !element.value) : this.form.parameters
      if (this.filtering.name) {
        parameters = parameters.filter(element => element.name.toLowerCase().includes(this.filtering.name.toLowerCase()))
      }

      return parameters
    },

    getMultipleDictionaryName(parameter, id) {
      return parameter.dictionaryNameMap.get(id)
    },

    selectDictionary(parameter, id) {
      parameter.value = id
    },

    async updateParameters() {
      const parameters = []

      for (const parameter of this.form.parameters) {
        if (!parameter.value) {
          continue
        }

        parameters.push({
          parameterId: parameter.allegroParameterId,
          parameterName: parameter.name,
          parameterType: parameter.type.toUpperCase(),
          values: [ String(parameter.value) ],
          required: parameter.required,
          requiredForProduct: parameter.requiredForProduct,
          describesProduct: parameter.describesProduct
        })
      }

      // eslint-disable-next-line no-useless-catch
      try {
        const json = {
          productId: this.shopProduct.productId,
          productVariationId: this.form.productVariation.id,
          parameters: parameters
        }

        await axios.post(this.isShopCatalog() ? `/catalog/shop/${this.shopId}/parameters` : `/catalog/user/parameters`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        throw error
      }
    },

    async loadAllegroAuctions(shopProduct) {
      try {
        const {data} = await axios.get(`/allegro/auction/auction-data`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            shopId: this.shopId,
            productId: shopProduct.productId
          }
        });

        this.allegroAuctions = JSON.parse(data.allegroAuctions)
        this.allegroAuctionErrors = data.allegroAuctionErrors

        for (const productVariation of this.productInfo.productVariation) {
          const allegroAuction = this.allegroAuctions.find(element => element.product_variation_id === productVariation.id)
          if (allegroAuction) {
            const error = this.allegroAuctionErrors.find(element => element.id === allegroAuction.id)
            productVariation.attributeError = error && error.error && error.error.includes("parameter")
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

}
</script>

<template>
  <div>
    <b-modal
        v-if="shopProduct && productInfo"
        size="xl"
        :scrollable="true"
        v-model="visibleModal"
        title="Szczegóły produktu"
        title-class="font-18"
        centered
        @hide="hideModal"
        @esc="hideModal">
      <div class="row">
        <div class="col-xl-7 col-md-12">
          <div class="row pb-3">
            <div class="col-xl-1 col-md-12 d-flex align-items-center justify-content-center justify-content-xl-start">
              <img :src="jsonUtil.asArray(shopProduct.productImages)[0]" class="img-info" alt="Product Icon">
            </div>
            <div class="col-xl-11 col-md-12 card-details-title">
              {{ shopProduct.productName }}
            </div>
          </div>

          <div class="row pb-2">
            <advanced-product-title title="Typ produktu:"
                                    :sub-title="productInfo.variationsCount > 1 ? 'Produkt z wariantami' : 'Bez wariantów'"/>
            <advanced-product-title title="Hurtownia:" :sub-title="warehouse() ? warehouse().name : ''"/>

            <advanced-product-title title="Kategoria:" :sub-title="category() ? category().name : '-'"/>
            <advanced-product-title title="Cena sklepu brutto:"
                                    :sub-title="productsHelper.parsePrice(form.productVariation.priceMarginTaxIncluded)"/>

            <advanced-product-title title="Łączny stan:"
                                    :sub-title="Number(productInfo.totalWarehouseStock + productInfo.totalDepotStock).toString()"/>
            <advanced-product-title title="Narzut:"
                                    :sub-title="productsHelper.profitMarginFormatter(null, null, shopProduct)"/>

            <advanced-product-title title="EAN:" :sub-title="eanString()"/>
            <advanced-product-title title="SKU:" :sub-title="shopProduct.productSku.toString()"/>

            <!--            <advanced-product-title title="Ścieżka kategorii ECAT:" sub-title="Zdrowie -> Erotyka -> Akcesoria i gadżety erotyczne –> Wibratory"/>-->
            <!--            <advanced-product-title title="Ścieżka kategorii hurtowni:" sub-title="Zdrowie -> Erotyka -> Akcesoria i gadżety erotyczne –> Wibratory"/>-->
          </div>

          <div class="row pb-3">
            <div class="col-xl-12 custom-title">
              Tytuł wariacji
            </div>

            <div class="col-xl-12">
              <div class="textarea-container">
                <textarea
                    @input="form.nameChanged = true"
                    v-model="form.productVariation.name"
                    placeholder="Wprowadź tytuł wariacji"
                    rows="2"
                    class="custom-textarea w-100">
                </textarea>
                <div class="textarea-footer">
                  <div class="footer-item highlight" @click="modifyWithAI('NAME')">
                    Zmodyfikuj tytuł z AI
                  </div>
                  <div class="footer-item" @click="resetName">
                    Przywróć domyślny tytuł
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-xl-12 custom-title">
              Opis wariacji
            </div>

            <div class="col-xl-12">
              <div class="textarea-container">
                <ecat-ckeditor @input="form.descriptionChanged = true" v-model="form.productVariation.description"
                               hide-header/>
                <div class="textarea-footer">
                  <div class="footer-item highlight" @click="modifyWithAI('DESCRIPTION')">
                    Zmodyfikuj opis z AI
                  </div>
                  <div class="footer-item" @click="resetDescription">
                    Przywróć domyślny opis
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-xl-12">
              <div class="custom-container">
                <div class="custom-container-header">
                  <div class="custom-title">
                    Atrybuty Wariacji ({{ form.parameters.length }})
                  </div>
                  <div class="custom-info">
                    Brakujące atrybuty: {{ missingAttributes }}
                  </div>
                </div>
                <div class="custom-container-body">
                  <div class="row nopadding search-container">
                    <div class="col-xl-8 d-inline-flex align-items-center">
                      <span class="pr-2">Szukaj:</span>
                      <ecat-input
                          v-model="filtering.name"
                          type="text"
                          inputClass="mb-0"
                      />
                    </div>
                    <div class="col-xl-4 d-inline-flex align-items-center">
                      <div class="custom-control custom-checkbox">
                        <input v-model="filtering.showAttributesWithErrors" id="show-attributes-with-errors"
                               type="checkbox" class="custom-control-input"/>
                        <label for="show-attributes-with-errors" class="custom-control-label">Pokaż tylko atrybuty z
                          błędami</label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12">
                      <div class="row attribute-body">
                        <div class="col-xl-4 attribute-padding" v-for="(parameter, index) in parameters()" :key="index">
                          <div class="attribute-box">
                            <span class="custom-label">Nazwa:</span>
                            <ecat-input
                                type="text"
                                :value="parameter.name"
                                hide-icon
                                disabled
                            />

                            <span class="custom-label pt-2">Wartość:</span>

                            <template v-if="parameter.type === 'string'">
                              <ecat-input
                                  v-model="parameter.value"
                                  :required="parameter.required"
                                  :min-length="parameter.restrictions.minLength"
                                  :max-length="parameter.restrictions.maxLength"
                                  type="text"
                                  input-class="mb-0"
                              />

                              <p v-if="parameter.value" class="badge position-absolute"
                                 :class="{ 'badge-success': parameter.value.length !== parameter.restrictions.maxLength, 'badge-danger': parameter.value.length === parameter.restrictions.maxLength }">
                                {{ parameter.value.length }} /
                                {{ parameter.restrictions.maxLength }}
                              </p>
                            </template>
                            <template v-else-if="parameter.type === 'float'">
                              <ecat-input
                                  v-model.number="parameter.value"
                                  :required="parameter.required"
                                  :min="parameter.restrictions.range ? parameter.restrictions.min : Number.MAX_SAFE_INTEGER"
                                  :max="parameter.restrictions.range ? parameter.restrictions.max : Number.MAX_SAFE_INTEGER"
                                  :step="parameter.restrictions.precision"
                                  type="number"
                                  input-class="mb-0"
                              />
                            </template>
                            <template v-else-if="parameter.type === 'integer'">
                              <ecat-input
                                  v-model.number="parameter.value"
                                  :required="parameter.required"
                                  type="number"
                                  input-class="mb-0"
                              />
                            </template>
                            <template v-else-if="parameter.type === 'dictionary'">
                              <template v-if="parameter.restrictions.multipleChoices">
                                <vue-multiselect
                                    v-model="parameter.value"
                                    :options="parameter.dictionaryIds"
                                    :custom-label="value => getMultipleDictionaryName(parameter, value)"
                                    :multiple="true"
                                    placeholder="Wybierz opcję"
                                    :show-labels="false"
                                />
                              </template>
                              <template v-else>
                                <vue-multiselect
                                    @select="value => selectDictionary(parameter, value)"
                                    :value="parameter.value"
                                    :options="parameter.dictionaryIds"
                                    :custom-label="value => getMultipleDictionaryName(parameter, value)"
                                    placeholder="Wybierz opcję"
                                    :show-labels="false"
                                />
                              </template>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-xl-12">
              <div class="custom-container">
                <div class="custom-container-header">
                  <div class="custom-title">
                    Galeria zdjęć produktu
                  </div>
                </div>

                <div class="custom-container-body">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="row">

                        <div class="image-title d-flex align-self-center align-self-xl-start">
                          <img src="@/assets/icons/favourite.svg" alt="Favourite Icon"/>
                          Obrazek wyróżniony
                        </div>

                        <div class="image-body w-100">
                          <div class="image-left-container">
                            <img :src="form.productVariation.images[0]" alt="Product Icon" class=""/>
                          </div>

                          <div class="image-right-container">
                            <div class="image-container"
                                 v-for="(image, index) in form.productVariation.images"
                                 :key="index">
                              <template v-if="image !== form.productVariation.images[0]">
                              <img
                                  :src="image"
                                  alt="Product Icon"
                              />

                              <div class="image-overlay">
                                <img @click="setFavouriteImage(index)" src="@/assets/icons/favourite.svg"
                                     alt="Favourite Icon"/>
                                <img @click="removeImage(index)" src="@/assets/icons/remove.svg" alt="Remove Icon"/>
                              </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="textarea-footer">
                  <!--                  <div class="footer-item highlight-blue">-->
                  <!--                    + Dodaj obrazek-->
                  <!--                  </div>-->
                  <div class="footer-item" @click="resetImages">
                    Przywróć domyślną galerię zdjęć
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-xl-12 custom-title">
              Koszt i formy dostawy dla klienta końcowego
            </div>

            <div class="col-xl-12">
              <div class="card-delivery-methods">
                <div class="row">
                  <advanced-product-title v-for="(shippingCost, index) in shippingCostsForProduct()"
                                          :title="shippingCost.name"
                                          :sub-title="priceHelper.format(shippingCost.cost, $store.getters['market/currentCurrency'])"
                                          :key="index"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-md-12">
          <div class="row pb-3">
            <div class="col-xl-12">
              <div class="custom-container">
                <div class="custom-container-header">
                  <div class="custom-title">
                    Wariacje Produktu ({{ productInfo.variationsCount }})
                  </div>
                  <div class="custom-info">
                    Błędy: {{ productInfo.productVariation.filter(element => element.attributeError).length }}
                  </div>
                </div>
                <div class="custom-container-body">
                  <div class="row nopadding search-container">
                    <div class="col-xl-6 d-inline-flex align-items-center pr-1 pl-0">
                      <span class="pr-2">Szukaj:</span>
                      <ecat-input
                          type="text"
                          inputClass="mb-0"
                      />
                    </div>
                    <div class="col-xl-6 d-inline-flex align-items-center pl-1 pr-0">
                      <div class="custom-control custom-checkbox">
                        <input id="test" type="checkbox" class="custom-control-input"/>
                        <label for="test" class="custom-control-label">Pokaż tylko wariacje z błędami</label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12">
                      <div class="row nopadding mini-product-containers">
                        <mini-product
                            v-for="(productVariation, index) in productInfo.productVariation"
                            :product-variation="productVariation"
                            @select="selectProductVariation"
                            :is-selected="productVariation.id === form.productVariation.id"
                            :key="index"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <div class="row w-100">
          <div class="col-6 col-xl-3 pl-0">
            <ecat-button :callback="saveProductVariationChanges" class="w-100" variant="success"
                         :message="$t('message.save')"/>
          </div>
          <div class="col-6 col-xl-3 pr-0">
            <b-button variant="danger" class="w-100" @click="hideModal">Anuluj</b-button>
          </div>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<style scoped>

.card-details-title {
  padding-left: 25px;
  font-size: 14px;
  font-weight: bold;
  color: var(--product-card-details-title);
}

.card-delivery-methods {
  background-color: var(--product-card-details-delivery-bg);

  padding: 10px 15px;

  border-radius: 5px;

  border: 1px solid var(--product-card-details-delivery-border);
}

.custom-title {
  padding-bottom: 10px;

  font-size: 12px;
  font-weight: bold;

  color: var(--product-card-details-custom-title);
}

.mini-product-containers {
  max-height: 700px;
  overflow-y: auto;
}

.custom-info {
  font-size: 12px;
  font-weight: bold;

  padding: 5px 10px;

  border-radius: 5px;

  color: var(--product-card-details-info);
  background-color: var(--product-card-details-info-bg)
}

.custom-container {
  background-color: var(--product-card-details-container-bg);

  border-radius: 5px;

  border: 1px solid var(--product-card-details-container-border);
}

.custom-container-header {
  background-color: var(--product-card-details-container-header-bg);

  border-radius: 5px 5px 0 0;

  height: 35px;

  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--product-card-details-container-header-border);

  padding: 5px 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-container-header .custom-title {
  padding-bottom: 0;
}

.custom-container-body {
  background-color: var(--product-card-details-container-body-bg);

  border-radius: 0 0 5px 5px;

  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: var(--product-card-details-container-body-border);
}

.search-container {
  padding: 10px !important;

  border-bottom: 1px solid var(--product-card-details-search-border);
}

.custom-control-label {
  font-size: 12px;
  font-weight: 700;
}

.custom-label {
  font-size: 12px;
  font-weight: bold;

  color: var(--product-card-details-label);
}

.attribute-body {
  padding: 15px;
}

.image-body {
  padding: 0 15px 15px 15px;

  display: flex;
  flex-direction: row;
}

.image-right-container {
  padding-left: 15px;

  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  gap: 15px;
}

.attribute-box {
  background-color: var(--product-card-details-attribute-bg);

  padding: 10px;

  border-radius: 5px;
  border: 1px solid var(--product-card-details-attribute-border);
}

.attribute-padding {
  padding-bottom: 7px;
}

@media (min-width: 1280px) {
  .attribute-padding:nth-child(3n+1) {
    padding-right: 7px;
  }

  .attribute-padding:nth-child(3n+2) {
    padding-left: 7px;
    padding-right: 7px;
  }

  .attribute-padding:nth-child(3n+3) {
    padding-left: 7px;
  }
}


.image-container {
  position: relative;
}

.image-container:hover .image-overlay {
  visibility: visible;
}

.image-overlay {
  visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--product-card-details-image-bg);
}

.image-overlay img {
  max-height: 13px;
}

.image-overlay img:hover {
  cursor: pointer;
}

.image-left-container {
  padding-left: 15px;
}

.image-left-container img {
  width: 140px;
  height: 140px;
  border-radius: 20%;
}

.image-container img {
  width: 62px;
  height: 62px;
  border-radius: 20%;
}

.image-title {
  padding-left: 30px;

  padding-bottom: 5px;
  padding-top: 10px;

  font-size: 12px;
  font-weight: bold;

  color: var(--product-card-details-image);
}
</style>