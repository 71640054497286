<template>
  <b-modal
      v-model="visibleModal"
      title="Wybór typu edycji"
      title-class="font-18"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal">

    <div class="form-group">
      <label>Typ</label>

      <div>
        <input type="radio" id="USER" value="USER" v-model="form.type" name="USER">
        <label for="USER" style="margin-left: 5px;">Użytkownik (wszystkie twoje sklepy)</label>
      </div>

      <div>
        <input type="radio" id="SHOP" value="SHOP" v-model="form.type" name="SHOP">
        <label for="SHOP" style="margin-left: 5px;">Sklep (obecny na którym pracujesz)</label>
      </div>
    </div>

    <div class="text-right">
      <b-button :disabled="!form.type" @click="select" variant="success">Wybierz</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import {routerHelper} from "@/helpers/router-helper";
import Swal from "sweetalert2";

export default {

  data() {
    return {
      visibleModal: false,

      form: {
        type: null
      }
    }
  },

  computed: {
    getSelectedShopId() {
      try {
        const params = routerHelper.params();
        const shopId = params.shopId

        if (shopId) {
          return shopId
        }
      } catch (error) {
        // ignore
      }

      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  methods: {
    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.type = null
    },

    select() {
      if (this.form.type === "SHOP" && !this.getSelectedShopId) {
        Swal.fire("", this.$t('core.select.shop'), "error");
        return
      }

      this.$emit("select", this.form.type)
      this.hideModal()
    }
  }

}
</script>