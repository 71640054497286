<script>
export default {
  name: "operation-price",

  props: {
    operation: { // add, equal, multiply, substract
      type: String,
      required: true
    }
  }

}
</script>

<template>
  <div class="operation-container d-flex align-items-center justify-content-center">
    <div class="operation-box" :data-operation="operation">
      <img :src="require(`@/assets/icons/operation/${operation}.svg`)" alt="Operation Icon"/>
    </div>
  </div>
</template>

<style scoped>
[data-operation="add"] {
  --operation-bg: var(--product-operation-add);
}

[data-operation="equals"] {
  --operation-bg: var(--product-operation-equals);
}

[data-operation="multiply"] {
  --operation-bg: var(--product-operation-multiply);
}

[data-operation="substract"] {
  --operation-bg: var(--product-operation-substract);
}

.operation-box {
  margin: 0 10px;

  background-color: var(--operation-bg);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;

  width: 39px;
  height: 39px;
}

@media (max-width: 1280px) {
  .operation-container {
    width: 100%;

    margin: 5px;
  }
}

</style>